import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import OnCallCellOverlay from './OnCallCellOverlay';
import TimesheetDayTitles from './TimesheetDayTitles';
import checkCompanyTable from '../hoc/companyConfig';

const mql = window.matchMedia(`(max-width: 1023px)`);

function OnCallSelectionRow({
  labels,
  mode,
  onClear,
  onSelect,
  optionsBuilder,
  row,
  savePending,
  totals,
  company
}) {
  const [mobile, setMobile] = useState(mql.matches);
  const [showPercentComplete, setShowPercentComplete] = useState(false);
  const [showOutlook, setShowOutlook] = useState(false);
  const mediaQueryChanged = () => {
    setMobile(mql.matches);
  };
  useEffect(() => {
    mql.addListener(mediaQueryChanged);
    return () => {
      mql.removeListener(mediaQueryChanged);
    };
  }, []);
  useEffect(() => {
    getCompanyTable(company);
  }, [company]);
  async function getCompanyTable(company) {
    const data = await checkCompanyTable(company);
    const percentCompleteEnabled = data.data.companyFlag[0].AllowTSPercent;
    const outlookEnabled = data.data.companyFlag[0].AllowTSOutlook;
    setShowPercentComplete(percentCompleteEnabled);
    setShowOutlook(outlookEnabled);
  }
  const showBoth = showPercentComplete && showOutlook;
  const showPercentOnly = showPercentComplete && !showOutlook;
  const showOutlookOnly = !showPercentComplete && showOutlook;
  const showNeither = !showPercentComplete && !showOutlook;
  const titleClass = classNames('title col-xs-12', {
    'col-md-2': showOutlookOnly || showPercentOnly || showBoth,
    'col-md-1': showNeither,
  });
  const totalClass = classNames('total col-xs-12', {
    'col-md-2': showOutlookOnly || showPercentOnly || showBoth,
    'col-md-3': showNeither,
  });
  const daysClass = classNames('days col-xs-12', {
    'col-md-6': showBoth,
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither,
  });
  return (
    <div className="timesheet-line row timesheet-days timesheet-line--oncall">
      <div className={titleClass}>
        <span>{row.Description}</span>
      </div>
      <div className={totalClass}>
        {row.TotalDays} day{row.TotalDays === 1 ? '' : 's'}
      </div>
      <div className={daysClass} style={{ paddingLeft: '21px' }}>
        <TimesheetDayTitles entries={row.entries} />
        <div className="day-inputs col-md-12 col-xs-9">
          {row.entries.map((entry, i) => (
            <OnCallCellOverlay
              options={optionsBuilder(i)}
              checked={entry.OnCall}
              onChange={onSelect}
              approved={totals[i].Approved}
              date={entry.Date}
              invalidSelection={entry.InvalidSelection}
              isSavePending={savePending[entry.id] || savePending[entry.Date]}
              key={entry.Date}
              labels={labels}
              mode={mode}            
              posted={totals[i].Posted}
              selection={entry.OnCall}
              submitted={totals[i].Submitted}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OnCallSelectionRow;
