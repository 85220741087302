import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Collapse } from 'react-bootstrap';
import { REQ_HEAD_LOOKUP_QUERY, REQ_DETAIL_LOOKUP_QUERY } from '../../../timesheet/queries';
import { CURRENT_USER_QUERY } from '../../../auth/queries';

const ViewOpenReqsModal = ({ onClose, company }) => {
  const [requisitionData, setRequisitionData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [reqDetailData, setReqDetailData] = useState({});
  const [requestorID, setRequestorID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: CURRENT_USER_QUERY,
          }),
        });
        const data = await response.json();
        const currentUser = data.data.currentUser;
        const formattedName = currentUser.Name.replace(/\s+/g, '.');

        setRequestorID(formattedName);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    }

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchRequisitions = async () => {
      if (!requestorID) return;

      setLoading(true);
      setError(null);

      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_HEAD_LOOKUP_QUERY,
            variables: { company, requestorID },
          }),
        });

        if (!response.ok) throw new Error('Failed to fetch requisitions');

        const result = await response.json();
        if (result.errors) {
          throw new Error(result.errors.map((err) => err.message).join(', '));
        }

        setRequisitionData(result.data.reqHead || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRequisitions();
  }, [company, requestorID]);

  const toggleRow = async (ReqNum) => {
    const isExpanded = expandedRows[ReqNum];
    setExpandedRows((prev) => ({
      ...prev,
      [ReqNum]: !isExpanded,
    }));

    if (!isExpanded && !reqDetailData[ReqNum]) {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_DETAIL_LOOKUP_QUERY,
            variables: { company, ReqNum },
          }),
        });

        const result = await response.json();
        if (result.errors) throw new Error('Failed to fetch requisition details');

        setReqDetailData((prevData) => ({
          ...prevData,
          [ReqNum]: result.data.reqDetail || [],
        }));
      } catch (err) {
        console.error(err.message);
      }
    }
  };

  return (
    <>
      <div className="custom-backdrop-overlay"></div>

      <Modal show onHide={onClose} size="lg" className="view-open-reqs-modal">
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title className="modal-title-custom">Open Requisitions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p className="text-center">Loading...</p>
          ) : error ? (
            <p className="text-danger text-center">Error fetching requisitions: {error}</p>
          ) : (
            <div className="table-container">
              <div className="table-header">
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th className="col-md-1">Requisition</th>
                      <th className="col-md-2">Requestor</th>
                      <th className="col-md-1">Request Date</th>
                      <th className="col-md-1">Created Date</th>
                      <th className="col-md-1">Open Requisition</th>
                      <th className="col-md-2">Status</th>
                      <th className="col-md-2">Approver</th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="table-body">
                <Table bordered hover responsive>
                  <tbody>
                    {requisitionData.map((req) => (
                      <React.Fragment key={req.ReqHead_ReqNum}>
                        <tr onClick={() => toggleRow(req.ReqHead_ReqNum)} className="req-header-row">
                          <td className="col-md-1">
                            {expandedRows[req.ReqHead_ReqNum] ? '▲' : '▼'}{' '}
                            {req.ReqHead_ReqNum}
                          </td>
                          <td className="col-md-2">{req.ReqHead_RequestorID}</td>
                          <td className="col-md-1">{req.ReqHead_RequestDate}</td>
                          <td className="col-md-1">{req.ReqHead_CreatedOn}</td>
                          <td className="col-md-1">{req.ReqHead_OpenReq ? 'Yes' : 'No'}</td>
                          <td className="col-md-2">{req.Calculated_Status}</td>
                          <td className="col-md-2">{req.Calculated_Approver}</td>
                        </tr>
                        <tr>
                          <td colSpan="7" className="req-detail-row">
                            <Collapse in={expandedRows[req.ReqHead_ReqNum]}>
                              <div>
                                {reqDetailData[req.ReqHead_ReqNum] ? (
                                  <Table striped bordered className="req-detail-table">
                                    <thead>
                                      <tr>
                                        <th>Req Line</th>
                                        <th>Part Number</th>
                                        <th>Description</th>
                                        <th>Project ID</th>
                                        <th>Job Number</th>
                                        <th>Order Qty</th>
                                        <th>Unit Cost</th>
                                        <th>Currency</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {reqDetailData[req.ReqHead_ReqNum].map((detail) => (
                                        <tr key={detail.ReqDetail_ReqLine}>
                                          <td>{detail.ReqDetail_ReqLine}</td>
                                          <td>{detail.ReqDetail_PartNum}</td>
                                          <td>{detail.ReqDetail_LineDesc}</td>
                                          <td>{detail.Project_ProjectID}</td>
                                          <td>{detail.JobHead_JobNum}</td>
                                          <td>{detail.ReqDetail_OrderQty}</td>
                                          <td>{detail.ReqDetail_UnitCost}</td>
                                          <td>{detail.ReqDetail_CurrencyCode}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                ) : (
                                  <p className="text-center">Loading details...</p>
                                )}
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="custom-close-button" onClick={onClose}>Close</Button>
        </Modal.Footer>

        <style jsx>{`
          .custom-backdrop-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1040; /* Below modal, above other content */
          }

          .view-open-reqs-modal {
            z-index: 1050;
            position: relative;
            color: #4a4a4a;
            max-width: 85vw;
            top: 5vh;
          }

          .view-open-reqs-modal .modal-content {
            border: none;
            border-radius: 8px;
            background-color: #ffffff;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
          }

          .modal-header-custom {
            background-color: #f8f9fa;
            padding: 16px 24px;
            border-bottom: 1px solid #dee2e6;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            text-align: center;
          }

          .modal-title-custom {
            font-size: 1.5rem;
            font-weight: 600;
            color: #343a40;
          }

          .table-container {
            max-height: 75vh;
            overflow-y: auto;
          }

          .table-header {
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: #f1f1f1;
            border-bottom: 1px solid #ddd;
          }

          .req-header-row {
            cursor: pointer;
            background-color: #fefefe;
            transition: background-color 0.3s ease;
          }

          .req-header-row:hover {
            background-color: #f1f1f1;
          }

          .req-detail-row {
            background-color: #f3f4f8;
            padding: 0 16px;
            border-radius: 8px;
            box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
          }

          .custom-close-button {
            background-color: #3264af;
            color: white;
            padding: 10px 20px;
            border-radius: 8px;
            border: none;
            font-size: 1.2rem;
            transition: background-color 0.3s ease;
          }

          .custom-close-button:hover {
            background-color: #0a3b84 !important;
            color: white !important;
          }

        `}</style>
      </Modal>
    </>
  );
};

export default ViewOpenReqsModal;
