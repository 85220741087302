import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TotalCell from './TotalCell';
import { getTimesheetTimeTrackingMethod } from '../api/methods';
import checkCompanyTable from '../hoc/companyConfig';

/**
 * <TimesheetTotals />
 * Total tally at bottom of the timesheet rows
 */
function TimesheetTotals({ timesheet, submitTimesheet, toggleMobileDashboard }) {

  const [showPercentComplete, setShowPercentComplete] = useState(false);
  const [showOutlook, setShowOutlook] = useState(false);

  useEffect(() => {
    getCompanyTable(timesheet.Company);
  }, [timesheet.Company]);

  async function getCompanyTable(company) {
    const data = await checkCompanyTable(company);

    const percentCompleteCompany = data.data.companyFlag[0].AllowTSPercent;
    setShowPercentComplete(percentCompleteCompany);

    const outlookCompany = data.data.companyFlag[0].AllowTSOutlook;
    setShowOutlook(outlookCompany);
  }

  // Step 3: Render the total cell with optional percentage
  function renderTotalCell(tot) {
    const isPercent = getTimesheetTimeTrackingMethod(timesheet).usePercentage;
    return (
      <TotalCell
        key={tot.Date}
        date={tot.Date}
        value={tot.Total}
        usePercentage={isPercent}
      />
    );
  }

  const showBoth = showPercentComplete && showOutlook;
  const showPercentOnly = showPercentComplete && !showOutlook;
  const showOutlookOnly = !showPercentComplete && showOutlook;
  const showNeither = !showPercentComplete && !showOutlook;

  const weeklyTotalsClass = classNames('weekly-totals col-xs-12', {
    'col-md-3': showOutlookOnly || showPercentOnly || showNeither || showBoth,
  });

  const timesheetDaysClass = classNames('timesheet-days col-xs-12', {
    'col-md-6': showBoth,
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither,
  });

  return (
    <div className="timesheet-totals row">
      {/* Weekly Totals */}
      <div className={weeklyTotalsClass}>
        <i
          className="fa fa-2x fa-th-large mobile-menu-toggle pull-left"
          aria-hidden="true"
          onClick={toggleMobileDashboard}
        />
        <div className="project-selection" />
      </div>

      {/* Timesheet Day Totals */}
      <div className={timesheetDaysClass}>
        {timesheet.totals.map(renderTotalCell)}
      </div>

      {/* Grand Total */}
      <div className="col-md-1 grand-total">
        <span>Total</span>
        <span className="timesheet-totals--grand-total">
          {timesheet.grandTotal}
        </span>
      </div>
    </div>
  );
}

TimesheetTotals.propTypes = {
  timesheet: PropTypes.object.isRequired,
  toggleMobileDashboard: PropTypes.func.isRequired,
  submitTimesheet: PropTypes.func.isRequired,
};

export default TimesheetTotals;