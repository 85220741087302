import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { OPERATION_OUTLOOKS } from '../../constants';

import checkCompanyTable from '../hoc/companyConfig'
import fetchHolidays from './Holiday';

/**
 * <TimesheetDayTitles />
 * Timesheet column header (for desktop devices)
 */
function TimesheetHeaderRow({startDate, totals, company}) {
  const [showPercentComplete, setShowPercentComplete] = useState(null);
  const [showOutlook, setShowOutlook] = useState(null);
  const [holidayDates, setHolidayDates] = useState([]);

  useEffect(() => {
    async function loadHolidays() {
      try {
        const holidays = await fetchHolidays(company);
        const formattedHolidays = holidays.map(date => moment(date).format('YYYY-MM-DD'));

        setHolidayDates(formattedHolidays);
      } catch (error) {
        console.error("Error fetching holidays:", error);
      }
    }
    loadHolidays();
  }, [company]);

  function isHoliday(date) {
    const formattedDate = date.format('YYYY-MM-DD');
    const isHolidayMatch = holidayDates.includes(formattedDate);
    return isHolidayMatch;
  }

  useEffect(() => {
    getCompanyTable(company);
  }, [company]);

  async function getCompanyTable(company) {
    const data = await checkCompanyTable(company);

    // Check if the company allows Percent Complete
    const percentCompleteCompany = data.data.companyFlag[0].AllowTSPercent;
    setShowPercentComplete(percentCompleteCompany);

  // Check if the company allows Outlook
    const outlookCompany = data.data.companyFlag[0].AllowTSOutlook;
    setShowOutlook(outlookCompany);
  }

  var d = moment(startDate);
  var entries = [];
  for (let i = 0; i < 7; i++) {
    entries.push(d.clone());
    d.add(1, 'day');
  }

  function renderEntryHeader(date, index) {
    const approved = totals[index].Approved;
    const submitted = !approved && totals[index].Submitted;
    const isHolidayDate = isHoliday(date);

    const cls = classNames(
      'timesheet-cell',
      'timesheet-cell--header',
      {'timesheet-cell--approved': approved},
      {'timesheet-cell--submitted': submitted}
    );

    return (
      <div className={cls} key={date.toDate()}>
        {date.format('ddd D')}
        {approved && <i className="fa fa-lock" />}
        {submitted && <i className="fa fa-check" />}
        {isHolidayDate && (
          <Tooltip
            overlay="Company holiday"
            placement="top"
            trigger={['hover']}
          >
            <span className="holiday-indicator" style={{ cursor: 'pointer' }}>&#x1F334;</span>
          </Tooltip>
        )}
      </div>
    );
  }

  // const timesheetProjectClass = classNames('timesheet-project', {
  //   'col-md-3': (company === '100' || company === '700' || company === '770'),
  //   'col-md-4': (company !== '100' && company !== '700' && company !== '770')
  // })
  // const timesheetDaysClass = classNames('timesheet-days', {
  //   'col-md-6': (company === '100' || company === '700' || company === '770'),
  //   'col-md-7': (company !== '100' && company !== '700' && company !== '770')
  // })

  const showBoth = showPercentComplete && showOutlook;
  const showPercentOnly = showPercentComplete && !showOutlook;
  const showOutlookOnly = !showPercentComplete && showOutlook;
  const showNeither = !showPercentComplete && !showOutlook;

  const timesheetDaysClass = classNames('timesheet-days', {
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither,
    'col-md-6': showBoth,
  })

  const timesheetProjectClass = classNames('timesheet-project', {
    'col-md-3': showOutlookOnly || showPercentOnly || showBoth,
    'col-md-4': showNeither,
  })

  return (
    <div className="timesheet-project-header">
      <div className="row">
        <div className={timesheetProjectClass}>
          <div className="project-heading">Project</div>
        </div>
        <div className={timesheetDaysClass}>
          <div className="day-titles">{entries.map(renderEntryHeader)}</div>
        </div>
        {showPercentComplete && (
          <div className='col-md-1 header-percent-complete'>Percent Complete</div>
        )}     
        {showOutlook && (
          <Tooltip
            overlay={<div>
              {OPERATION_OUTLOOKS.slice(1).map(outlook => (
                <p key={outlook.VALUE}><span role='img'>{String.fromCodePoint(outlook.EMOJI)} {outlook.VALUE} - {outlook.STRING}</span></p>
              ))}
            </div>}
            trigger='hover'
            placement={'bottom'}>
            <div className='col-md-1 header-op-outlook'>Outlook</div>
          </Tooltip>
        )}                  
        <div className="col-md-1 header-totals">Totals</div>
      </div>
    </div>
  );
}
TimesheetHeaderRow.propTypes = {
  startDate: PropTypes.string.isRequired,
  totals: PropTypes.array.isRequired,
  company: PropTypes.string.isRequired
};

export default TimesheetHeaderRow;