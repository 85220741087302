import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import buildProjectOptions from '../api/buildProjectOptions';
import { inLieuOptions } from '../../constants';

import Banner from '../../shared/components/Banner';
import DayTypeSelectionRow from './DayTypeSelectionRow';
import TimesheetFooter from './TimesheetFooter';
import TimesheetHeaderRow from './TimesheetHeaderRow';
import TimesheetLine from './TimesheetLine';
import TimesheetSubTotals from './TimesheetSubTotals';
import TimesheetWidgetTotals from './TimesheetWidgetTotals';
import OnCallSelectionRow from './OnCallSelectionRow';

// Import the company table check function
import checkCompanyTable from '../hoc/companyConfig';

// Utility function for matching elements
const matches = (el, selector) => {
  return (
    el.matches ||
    el.matchesSelector ||
    el.msMatchesSelector ||
    el.mozMatchesSelector ||
    el.webkitMatchesSelector ||
    el.oMatchesSelector
  ).call(el, selector);
};

const buildInLieuOptions = () => inLieuOptions;

const TimesheetDetail = (props) => {
  const [showOnCall, setShowOnCall] = useState(false);
  const [showOvernight, setShowOvernight] = useState(false);

  const {
    activeRow,
    clearInLieu,
    clearOvernightComp,
    editRow,
    forDashboard,
    labels,
    mode,
    saveOnCall,
    saveInLieu,
    saveOvernightComp,
    savePending,
    timesheet,
    setTimesheetUser,
    setActiveRow,
    timesheetSource,
    currentUser,
  } = props;

  // Fetch company-specific flags for On Call and Overnight using GraphQL
  useEffect(() => {
    const fetchCompanySettings = async () => {
      try {
        const data = await checkCompanyTable(timesheet.Company);

        // Check if the company allows On Call
        const onCallEnabled = data?.data?.companyFlag[0]?.AllowOnCall;
        setShowOnCall(onCallEnabled || false);

        // Check if the company allows Overnight
        const overnightEnabled = data?.data?.companyFlag[0]?.AllowOvernight;
        setShowOvernight(overnightEnabled || false);
      } catch (error) {
        console.error('Error fetching company settings:', error);
      }
    };

    fetchCompanySettings();
  }, [timesheet.Company]);

  const cls = classNames('timesheet-detail clearfix', {
    weekends: true,
    'timesheet--save-pending': savePending[timesheet.id],
  });

  const isTimesheetReadOnly = timesheet.totals.every(
    (x) => x.Approved || x.Posted || (mode === 'timesheet' && x.Submitted)
  );

  const headerProps = {
    labels,
    mode,
    isTimesheetReadOnly,
    startDate: timesheet.PeriodStartDate,
    totals: timesheet.totals,
    company: timesheet.Company,
  };

  // Update label description based on company
  timesheet.overnightRow.Description =
    timesheetSource.Company === '100' ? 'On the Road' : 'Overnight';

  const isApproved = !timesheet.isPosted && timesheet.isApproved;
  const isSubmitted =
    !(timesheet.isPosted || timesheet.isApproved) && timesheet.isSubmitted;
  const unsubmittedTime = timesheet.UnsubmittedTime.length > 0;

  const handleMouseDown = useCallback(
    (e) => {
      if (!matches(e.target, '.timesheet-line--active, .timesheet-line--active *')) {
        setActiveRow(null);
      }
    },
    [setActiveRow]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown]);

  return (
    <div className={cls}>
      {timesheet.isPosted && <Banner bgStyle="default" text="Posted" />}
      {isApproved && <Banner bgStyle="success" text="Approved" />}
      {mode === 'timesheet' && isSubmitted && <Banner bgStyle="info" text="Submitted" />}
      {unsubmittedTime && (
        <Banner
          bgStyle="unsubmitted"
          text={
            <div>
              <div>
                {currentUser.id === `${timesheet.EmployeeNum}:${timesheet.Company}`
                  ? `You have unsubmitted time for the week${timesheet.UnsubmittedTime.length === 1 ? '' : 's'} of`
                  : `${timesheet.EmployeeName} has unsubmitted time for the week${timesheet.UnsubmittedTime.length === 1 ? '' : 's'} of`}
              </div>
              <div>
                {timesheet.UnsubmittedTime.map((time) => (
                  <Link
                    onClick={() =>
                      setTimesheetUser({
                        value: `${timesheet.EmployeeNum}:${timesheet.Company}`,
                      })
                    }
                    to={`/timesheet/${time}`}
                    className="timesheet-detail-unsubmitted--link"
                    key={time}
                  >
                    {time}
                  </Link>
                )).reduce((prev, curr) => [prev, ' | ', curr])}
              </div>
            </div>
          }
        />
      )}

      {React.cloneElement(React.Children.only(props.children), {
        ...props,
        ...headerProps,
      })}

      <TimesheetHeaderRow {...headerProps} />

      <div className="timesheet-detail-scroll">
        <div className="timesheet-detail-inner col-md-12">
          {timesheet.rows.map((row, i) => (
            <TimesheetLine
              key={i}
              isActive={i === activeRow}
              isEdit={i === editRow}
              isSavePending={savePending[row.id] || savePending[timesheet.id] || false}
              isTimesheetReadOnly={isTimesheetReadOnly}
              mode={mode}
              row={row}
              rowIndex={i}
              {...props}
            />
          ))}

          <TimesheetSubTotals totals={timesheet.totals} {...headerProps} />

          {/* Overnight row if allowed */}
          {showOvernight && (
            <DayTypeSelectionRow
              {...headerProps}
              onClear={clearOvernightComp}
              onSelect={saveOvernightComp}
              optionsBuilder={buildProjectOptions(timesheet)}
              row={timesheet.overnightRow}
              savePending={savePending}
            />
          )}

          {/* In Lieu for UK only */}
          {timesheetSource.Company !== '100' && (
            <DayTypeSelectionRow
              {...headerProps}
              onClear={clearInLieu}
              onSelect={saveInLieu}
              optionsBuilder={buildInLieuOptions}
              row={timesheet.inLieuRow}
              savePending={savePending}
            />
          )}

          {/* On call for US employees */}
          {showOnCall && timesheetSource.OnCallEmployee === true && (
            <OnCallSelectionRow
              {...headerProps}
              row={timesheet.onCallRow}
              optionsBuilder={buildProjectOptions(timesheet)}
              onSelect={saveOnCall}
              savePending={savePending}
            />
          )}

          {forDashboard && <TimesheetWidgetTotals {...headerProps} />}
        </div>
      </div>
      {!forDashboard && <TimesheetFooter {...props} />}
    </div>
  );
};

TimesheetDetail.propTypes = {
  activeEntry: PropTypes.number,
  activeRow: PropTypes.number,
  editRow: PropTypes.number,
  forDashboard: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  savePending: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  uiState: PropTypes.object.isRequired,
  timesheetSource: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setActiveRow: PropTypes.func.isRequired,
  clearInLieu: PropTypes.func.isRequired,
  clearOvernightComp: PropTypes.func.isRequired,
  saveOnCall: PropTypes.func.isRequired,
  saveInLieu: PropTypes.func.isRequired,
  saveOvernightComp: PropTypes.func.isRequired,
  setTimesheetUser: PropTypes.func.isRequired,
};

export default TimesheetDetail;