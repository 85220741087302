import { HOLIDAY_LOOKUP_QUERY } from '../queries';
import moment from 'moment';

async function fetchHolidays(CompanyID) {
  try {
    const response = await fetch('/api/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: HOLIDAY_LOOKUP_QUERY,
        variables: {
          company: CompanyID,
        },
      }),
    });

    if (!response.ok) {
      throw new Error('Holiday list fetch failed');
    }

    const result = await response.json();
    const holidays = result.data?.holidayLookup || [];

    const formattedHolidays = holidays.map(holiday => 
      moment(holiday.PRHoldy_Holiday).format('YYYY-MM-DD')
    );

    return formattedHolidays;
  } catch (error) {
    console.error('Error fetching holiday list:', error);
    return [];
  }
}

export default fetchHolidays;
