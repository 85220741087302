import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TotalCell from './TotalCell';
import checkCompanyTable from '../hoc/companyConfig';

const mql = window.matchMedia(`(max-width: 1023px)`);

const TimesheetSubTotals = ({ totals, company }) => {
  const [mobile, setMobile] = useState(mql.matches);
  const [showPercentComplete, setShowPercentComplete] = useState(false);
  const [showOutlook, setShowOutlook] = useState(false);

  const mediaQueryChanged = () => {
    setMobile(mql.matches);
  };

  useEffect(() => {
    mql.addListener(mediaQueryChanged);

    return () => {
      mql.removeListener(mediaQueryChanged);
    };
  }, []);

  useEffect(() => {
    getCompanyTable(company);
  }, [company]);

  async function getCompanyTable(company) {
    const data = await checkCompanyTable(company);
    const percentCompleteEnabled = data.data.companyFlag[0].AllowTSPercent;
    const outlookEnabled = data.data.companyFlag[0].AllowTSOutlook;

    setShowPercentComplete(percentCompleteEnabled);
    setShowOutlook(outlookEnabled);
  }

  const showBoth = showPercentComplete && showOutlook;
  const showPercentOnly = showPercentComplete && !showOutlook;
  const showOutlookOnly = !showPercentComplete && showOutlook;
  const showNeither = !showPercentComplete && !showOutlook;

  const titleClass = classNames('title col-xs-12', {
    'col-md-3': showOutlookOnly || showPercentOnly || showBoth,
    'col-md-4': showNeither,
  });

  const subtotalsClass = classNames('subtotals col-xs-12', {
    'col-md-6': showBoth,
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither,
  });

  const daysClass = classNames('days col-xs-12', {
    'col-md-6': showBoth,
    'col-md-7': showOutlookOnly || showPercentOnly || showNeither,
  });

  return (
    <div className="timesheet-subtotals">
      {renderRow('Chargeable Hours', totals, 'Chargeable', titleClass, subtotalsClass, daysClass, mobile)}
      {renderRow('Non-Chargeable Hours', totals, 'NonChargeable', titleClass, subtotalsClass, daysClass, mobile)}
      {totals.reduce((prev, cur) => prev + cur.TimeOff, 0) > 0
        ? renderRow('Time Off', totals, 'TimeOff', titleClass, subtotalsClass, daysClass, mobile, true)
        : null}
    </div>
  );
};

function renderRow(label, totals, prop, titleClass, subtotalsClass, daysClass, mobile, timeOff = false) {
  return (
    <div className="timesheet-line timesheet-line--subtotals row">
      <div className={titleClass}>{label}</div>
      <div className={mobile ? subtotalsClass : daysClass}>
        {totals.map((val) => (
          <TotalCell
            key={val.Date}
            includeDayOfWeek={mobile}
            date={val.Date}
            value={val[prop]}
            timeOff={timeOff}
            mobile={mobile}
          />
        ))}
      </div>
    </div>
  );
}

TimesheetSubTotals.propTypes = {
  totals: PropTypes.array.isRequired,
  company: PropTypes.string.isRequired,
};

export default TimesheetSubTotals;